// getNftMetadata: Gets the nft meta data for use in the nft details' page meta tags
import { getCloudinaryMediaUrl } from 'cb-wallet-data/stores/Collection/utils/getCloudinaryMediaUrl';

export type NftData = {
  image: string;
  imagePreview: string;
  name: string;
  description: string;
};

type Options = {
  cachePath: string;
  mimeType: string;
  preview: boolean;
  retrievalMethod?: 'fetch' | 'upload';
};

export async function fetchMediaContentType(url: string) {
  if (!url) {
    return '';
  }

  try {
    const res = await fetch(url);
    if (res.headers.get('content-type')) {
      return res.headers.get('content-type');
    }
    return '';
  } catch (e) {
    return '';
  }
}

export async function getCachedMediaUrl({
  cachePath,
  mimeType,
  preview,
  retrievalMethod = 'upload',
}: Options) {
  const size = preview ? 329 : 569;
  const mediaType = mimeType?.includes('audio') || mimeType?.includes('video') ? 'video' : 'image';
  const cloudinaryLookUpUrl = getCloudinaryMediaUrl({
    media: cachePath,
    options: {
      width: size,
      retrievalMethod,
    },
    type: mediaType,
  });
  // check the cloudinary url to check the file type of the asset
  const contentType = await fetchMediaContentType(cloudinaryLookUpUrl);
  // if the asset isn't an OpenGraph supported type, fall back to the jpg cloudinaryformat
  const CONTENT_TYPE_TO_FORMAT_MAP: Record<string, string> = {
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    fallback: 'jpg',
  };

  const format =
    contentType && CONTENT_TYPE_TO_FORMAT_MAP[contentType]
      ? CONTENT_TYPE_TO_FORMAT_MAP[contentType]
      : CONTENT_TYPE_TO_FORMAT_MAP.fallback;
  const cloudinaryFormattedUrl = getCloudinaryMediaUrl({
    media: cachePath,
    options: {
      width: size,
      transformationType: format,
      retrievalMethod,
    },
    type: mediaType,
  });
  return cloudinaryFormattedUrl;
}

export async function getNftMetadata(
  contractAddress: string,
  tokenId: string | undefined,
): Promise<NftData> {
  // Handle ERC-721 tokens, TOKEN_API_URL requires an ID, use 1 as most likely to exist
  let validatedTokenId;
  if (tokenId === 'undefined' || tokenId === undefined) {
    validatedTokenId = '1';
  } else {
    validatedTokenId = tokenId;
  }
  const TOKEN_API_URL = `https://api.wallet.coinbase.com/rpc/v3/collectibles/getTokenDetails?contractAddress=${contractAddress}&tokenId=${validatedTokenId}&chainId=8453&includeFloorPrice=false`;
  const TRENDING_MINT_API_URL = `https://api.wallet.coinbase.com/rpc/v3/creators/trendingMint?network=networks%2Fbase-mainnet&address=${contractAddress}`;

  let tokenDetails;
  let trendingMint;
  try {
    const response = await fetch(TOKEN_API_URL);
    tokenDetails = await response.json();

    const trendingMintResponse = await fetch(TRENDING_MINT_API_URL);
    trendingMint = await trendingMintResponse.json();
  } catch (error) {
    // console.error('Error fetching token details:', error);
  }

  const metadata = {} as NftData;

  if (
    trendingMint?.collection?.kind === 'ContractKindERC721' ||
    tokenId === 'undefined' ||
    tokenId === undefined
  ) {
    metadata.name = trendingMint?.collection?.name ?? tokenDetails?.collectionName;
    metadata.description =
      trendingMint?.collection?.description ??
      tokenDetails?.description ??
      tokenDetails?.collectionDescription;

    // For ERC-721 tokens, use the cachedCollectionImg to retrieve the generic
    // image for the entire collection
    metadata.image = await getCachedMediaUrl({
      cachePath:
        tokenDetails?.cachedCollectionImg?.cachedPath ||
        tokenDetails?.cachedImageUrl?.cachedPath ||
        tokenDetails?.cachedAnimationUrl?.cachedPath,
      mimeType:
        tokenDetails?.cachedCollectionImg?.mimeType ||
        tokenDetails?.cachedImageUrl?.mimeType ||
        tokenDetails?.cachedAnimationUrl?.mimeType,
      preview: false,
    });
    metadata.imagePreview = await getCachedMediaUrl({
      cachePath:
        tokenDetails?.cachedCollectionImg?.cachedPath ||
        tokenDetails?.cachedImageUrl?.cachedPath ||
        tokenDetails?.cachedAnimationUrl?.cachedPath,
      mimeType:
        tokenDetails?.cachedCollectionImg?.mimeType ||
        tokenDetails?.cachedImageUrl?.mimeType ||
        tokenDetails?.cachedAnimationUrl?.mimeType,
      preview: true,
    });
  } else {
    metadata.name =
      trendingMint?.collection?.tokenDetails?.name ??
      tokenDetails?.name ??
      trendingMint?.collection?.name;
    metadata.description =
      trendingMint?.collection?.tokenDetails?.description ??
      tokenDetails?.description ??
      trendingMint?.collection?.description;

    // For ERC-1155 tokens, we don't want to look at collection image because individual tokens
    // likely have their own artwork
    metadata.image = await getCachedMediaUrl({
      cachePath:
        tokenDetails?.cachedImageUrl?.cachedPath || tokenDetails?.cachedAnimationUrl?.cachedPath,
      mimeType:
        tokenDetails?.cachedImageUrl?.mimeType || tokenDetails?.cachedAnimationUrl?.mimeType,
      preview: false,
    });
    metadata.imagePreview = await getCachedMediaUrl({
      cachePath:
        tokenDetails?.cachedImageUrl?.cachedPath || tokenDetails?.cachedAnimationUrl?.cachedPath,
      mimeType:
        tokenDetails?.cachedImageUrl?.mimeType || tokenDetails?.cachedAnimationUrl?.mimeType,
      preview: true,
    });
  }

  // If the token has no image data at all, use the trending mint image if it exists
  const shouldUseTrendingMintImage = !(
    tokenDetails?.cachedCollectionImg?.cachedPath ??
    tokenDetails?.cachedImageUrl?.cachedPath ??
    tokenDetails?.cachedAnimationUrl?.cachedPath
  );

  if (
    shouldUseTrendingMintImage &&
    trendingMint?.collection?.tokenforgeMint &&
    trendingMint?.collection?.imageUrl
  ) {
    metadata.image = await getCachedMediaUrl({
      cachePath: trendingMint?.collection?.imageUrl,
      mimeType: 'image/jpeg',
      preview: false,
      retrievalMethod: 'fetch',
    });
    metadata.imagePreview = await getCachedMediaUrl({
      cachePath: trendingMint?.collection?.imageUrl,
      mimeType: 'image/jpeg',
      preview: true,
      retrievalMethod: 'fetch',
    });
  }

  return metadata;
}
