import { postJSON } from 'cb-wallet-http/fetchJSON';

import { GetSearchResponse, GlobalSearchEntity, SearchEntity } from './types';
import { getUrl, SearchPath } from './util';

export type GetSearchResultsParams = {
  query: string;
  searchType: SearchPath;
  requestId?: string;
  entities?: SearchEntity[] | GlobalSearchEntity[];
  pageSize?: number;
  maxResults?: number;
  baseUrlOverride?: string; // provide an override to base url that depends on the environment, e.g. for dapp
  isEnabled?: boolean;
  isWacSearchEnabled?: boolean;
  isNLPSearchEnabled?: boolean;
  isSocialSearchEnabled?: boolean;
};

export async function getSearchResults({
  query,
  searchType,
  requestId,
  entities,
  pageSize,
  maxResults,
  baseUrlOverride,
  isWacSearchEnabled,
  isNLPSearchEnabled = false,
  isSocialSearchEnabled = false,
}: GetSearchResultsParams) {
  const url = getUrl(searchType, baseUrlOverride);
  const params = {
    requestId,
    pageSize,
    maxResults,
    filter: {
      entities,
    },
    query,
    enableWac: isWacSearchEnabled,
    enableNlp: isNLPSearchEnabled,
    enableSocial: isSocialSearchEnabled,
  };
  return postJSON<GetSearchResponse>(url, params, {
    isThirdParty: true,
    authenticated: false,
  });
}
