import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { txSubmittedFail, txSubmittedSuccess } from '../data/Transactions';
import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';
import { EventProperties } from '../utils/types/eventProperties';

export type OnrampContextDataEventProperties = Pick<
  EventProperties,
  | 'asset'
  | 'amount'
  | 'market'
  | 'network'
  | 'provider'
  | 'chainId'
  | 'fiatCode'
  | 'chainName'
  | 'isConnected'
  | 'isOnrampMgx'
  | 'isOnrampSwap'
  | 'paymentMethodId'
>;

export type OnrampCommonEventProperties = OnrampContextDataEventProperties &
  Pick<
    EventProperties,
    | 'url'
    | 'host'
    | 'type'
    | 'error'
    | 'source'
    | 'screen'
    | 'context'
    | 'quoteId'
    | 'slippage'
    | 'networkFee'
    | 'navigateTo'
    | 'isConnected'
    | 'currencyCode'
    | 'payoutAmount'
    | 'chainPrefix'
    | 'currencyMode'
    | 'exchangeRate'
    | 'errorMessage'
    | 'paymentMethodUuid'
    | 'hasPreviousTransaction'
  >;

export function logOnrampErrorFallback(properties: OnrampCommonEventProperties) {
  logDataEvent('onramp.error.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function useLogOnrampExternalProviderWebviewViewed(properties: OnrampCommonEventProperties) {
  useLogEventOnMount('onramp.external_provider_webview.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampExternalProviderWebviewNavigated(properties: OnrampCommonEventProperties) {
  logEvent('onramp.external_provider_webview.navigated', {
    action: ActionType.view,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampDefaultOptions(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry_default_options.loaded', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampLaunched(properties: OnrampCommonEventProperties) {
  logEvent('onramp.flow.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function useLogOnrampEntryViewed(properties: OnrampCommonEventProperties = {}) {
  useLogEventOnMount('onramp.entry.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function useLogOnrampUnsupportedPaymentMethodTrayViewed(
  properties: OnrampCommonEventProperties,
) {
  useLogEventOnMount('onramp.unsupported_payment_method_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampExit(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry.exited', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logOnrampPressSelectCell(variant: 'payment-method' | 'asset' | 'network') {
  logEvent('onramp.entry_select_cell.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    variant,
  });
}

export function useLogOnrampProviderSelectorTrayOnMount() {
  useLogEventOnMount('onramp.provider_selector_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampLastUsedProviderTrayViewed() {
  logEvent('onramp.last_used_provider_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampLastUsedProviderTrayPress(variant: 'close' | 'continue') {
  logEvent('onramp.last_used_provider_tray.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    variant,
  });
}

export function logOnrampEntryPreviewPressed(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry_preview.pressed', {
    action: ActionType.render,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logOnrampEntryQuoteLoaded(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry_quote_preview.loaded', {
    action: ActionType.render,
    componentType: ComponentType.text,
    ...properties,
  });
}

export function logOnrampEntryQuoteError({ errorMessage }: OnrampCommonEventProperties) {
  logEvent('onramp.entry_quote_preview.error', {
    action: ActionType.render,
    componentType: ComponentType.text,
    errorMessage,
  });
}

export function logOnrampEntryQuoteContinuePressed(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry_quote_preview_continue.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function useLogOnrampEntryQuotePreview(properties: OnrampCommonEventProperties) {
  useLogEventOnMount('onramp.entry_quote_preview.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    ...properties,
  });
}

type LogOnrampEntryErrorParams = {
  error:
    | 'max-out-of-range'
    | 'min-out-of-range'
    | 'payment-method-unavailable'
    | 'limit-min-max-out-of-range';
  provider?: string;
  paymentMethod?: string;
};

export function logOnrampEntryError({ error, provider, paymentMethod }: LogOnrampEntryErrorParams) {
  logEvent('onramp.entry.error', {
    action: ActionType.render,
    componentType: ComponentType.page,
    error,
    provider,
    paymentMethodId: paymentMethod,
  });
}

export function logOnrampAssetSelected(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry.asset_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampPaymentMethodSelected(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry.payment_method_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampProviderSelected(properties: OnrampCommonEventProperties) {
  logEvent('onramp.entry.provider_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampMoreOptionClicked() {
  logEvent('onramp.entry.more_option_clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logOnrampChangeCurrencySelected() {
  logEvent('onramp.entry.change_currency_selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logOnrampCurrencySelected(properties: OnrampCommonEventProperties) {
  logEvent('onramp.currency.selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logMoreOptionTrayViewed() {
  logEvent('onramp.more_option_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampMgxAssetTrayOptionClicked(properties: OnrampCommonEventProperties) {
  logEvent('onramp.mgx_asset_tray_option.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function useLogOnrampSelectCurrencyViewed() {
  useLogEventOnMount('onramp.select_currency.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSelectAssetViewed() {
  useLogEventOnMount('onramp.select_asset.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSelectPaymentMethodViewed(
  properties: OnrampCommonEventProperties = {},
) {
  useLogEventOnMount('onramp.select_payment_method.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function useLogOnrampSelectNetworkViewed() {
  useLogEventOnMount('onramp.select_network.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampNetworkFilterViewed() {
  useLogEventOnMount('onramp.network_filter.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSwappableAssetTrayViewed() {
  useLogEventOnMount('onramp.swappable_asset_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampMgxAssetTrayViewed(properties: OnrampCommonEventProperties) {
  useLogEventOnMount('onramp.mgx_asset_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function useLogMgxAssetUnsupportedErrorModalViewed(properties: OnrampCommonEventProperties) {
  useLogEventOnMount('onramp.mgx_asset_unsupported_error_modal.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampNetworkFilterSelected(chainId?: string | number | null) {
  logEvent('onramp.network_filter.selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    chainId: chainId ?? undefined,
  });
}

export function logOnrampTransactionSubmitted(properties: OnrampCommonEventProperties) {
  logEvent('onramp.transaction.submitted', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampTransactionSuccess(properties: OnrampCommonEventProperties) {
  logEvent(txSubmittedSuccess, {
    action: ActionType.measurement,
    componentType: ComponentType.unknown,
    txSource: 'MGX_onramp',
    ...properties,
  });
}

export function logOnrampTransactionFailure(properties: OnrampCommonEventProperties) {
  logEvent(txSubmittedFail, {
    action: ActionType.measurement,
    componentType: ComponentType.unknown,
    txSource: 'MGX_onramp',
    ...properties,
  });
}

export function logOnrampTransactionError(properties: OnrampCommonEventProperties) {
  logEvent('onramp.transaction.error', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampExternalProviderFlowLaunched(
  properties: OnrampCommonEventProperties = {},
) {
  logEvent('onramp.external_provider.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampProviderFlowLaunched(properties: OnrampCommonEventProperties) {
  logEvent('onramp.provider.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampDeviceBrowserLaunched(properties: OnrampCommonEventProperties) {
  logEvent('onramp.device_browser.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampIABLaunched(isInAppBrowserAvailable: boolean) {
  logEvent('onramp.iab.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    enabled: isInAppBrowserAvailable,
  });
}

export function logOnrampIABResponse(type?: string) {
  logEvent('onramp.iab.response', {
    action: ActionType.render,
    componentType: ComponentType.page,
    type,
  });
}

export function logOnrampUnavailableProvider({
  asset = '',
  chainId = '',
  provider = '',
  newProvider = '',
}: {
  asset?: string;
  chainId?: string;
  provider?: string;
  newProvider?: string;
}) {
  logMetric({
    metricName: 'onramp.unavailable_provider',
    metricType: MetricType.count,
    value: 1,
    tags: {
      asset,
      chainId,
      provider,
      newProvider,
    },
  });
}

export function logOnrampAuthLaunched() {
  logEvent('onramp.authorization.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampAuthCompleted() {
  logEvent('onramp.authorization.completed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampNativeBuyConnectSucceeded(props: OnrampCommonEventProperties) {
  logEvent('onramp.native_buy_connected.succeeded', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    ...props,
  });
}
