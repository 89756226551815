import { getCachedMediaUrl, RetrievalMethod } from '@cbhq/nft-shared/utils';

import { getMediaContentType } from './validateMediaUrl';

type GetMediaInfoProps = {
  imageUrl?: string;
  animationUrl?: string;
  cachedImagePath?: string;
  cachedImageMimeType?: string;
  cachedAnimationPath?: string;
  cachedAnimationMimeType?: string;
  cachedMediaEnabled?: boolean;
  preview?: boolean;
  retrievalMethod?: RetrievalMethod;
  qualityPercentage?: number;
};

export type MediaInfo = {
  source?: string;
  poster?: string;
  mediaType?: string;
  fromCache?: boolean;
};

export async function getMediaInfo({
  animationUrl: originalAnimationUrl,
  imageUrl: originalImageUrl,
  cachedImagePath,
  cachedImageMimeType,
  cachedAnimationPath,
  cachedAnimationMimeType,
  cachedMediaEnabled,
  preview = false,
  retrievalMethod = RetrievalMethod.UPLOAD,
  qualityPercentage,
}: GetMediaInfoProps): Promise<MediaInfo> {
  if (!originalImageUrl && !originalAnimationUrl && !cachedImagePath && !cachedAnimationPath) {
    return {
      mediaType: '',
      source: '',
      poster: '',
      fromCache: false,
    };
  }

  const shouldUseCachedImage = !!(cachedMediaEnabled && cachedImagePath && cachedImageMimeType);
  const shouldUseCachedAnimation = !!(
    cachedMediaEnabled &&
    cachedAnimationPath &&
    cachedAnimationMimeType
  );

  const imageUrl = shouldUseCachedImage
    ? getCachedMediaUrl({
        preview,
        cachePath: cachedImagePath,
        mimeType: cachedImageMimeType,
        retrievalMethod,
        qualityPercentage,
      })
    : originalImageUrl;
  const animationUrl = shouldUseCachedAnimation
    ? getCachedMediaUrl({
        preview,
        cachePath: cachedAnimationPath,
        mimeType: cachedAnimationMimeType,
        retrievalMethod,
        qualityPercentage,
      })
    : originalAnimationUrl;

  if (preview && imageUrl) {
    const imageContentType = await (async () => {
      // cloudinary transcodes all image media into webp so we can just return the mimeType as image/webp for the cloudinary case
      // we also transform cached videos into image thumbnails if the preview flag is true
      // audio does not get transcoded though
      if (shouldUseCachedImage) {
        const isTranscodedMedia = !cachedImageMimeType.startsWith('audio');
        return isTranscodedMedia ? 'image/webp' : cachedImageMimeType;
      }

      return getMediaContentType(imageUrl);
    })();

    return {
      mediaType: imageContentType,
      source: imageUrl,
      poster: imageUrl,
      fromCache: Boolean(shouldUseCachedImage),
    };
  }

  // display cloudinary videos as images on NFT tab
  // to improve performance
  if (preview && animationUrl) {
    const animationContentType = await (async () => {
      if (shouldUseCachedAnimation) {
        const isTranscodedMedia = !cachedAnimationMimeType.startsWith('audio');
        return isTranscodedMedia ? 'image/webp' : cachedAnimationMimeType;
      }

      return getMediaContentType(animationUrl);
    })();

    return {
      mediaType: animationContentType,
      source: animationUrl,
      poster: animationUrl,
      fromCache: Boolean(shouldUseCachedAnimation),
    };
  }

  const imageUrlContentTypePromise = (async () => {
    // cloudinary transcodes all image media into webp so we can just return the mimeType as image/webp for the cloudinary case
    // video and audio do not get transcoded though
    if (shouldUseCachedImage) {
      const isTranscodedMedia =
        !cachedImageMimeType.startsWith('video') && !cachedImageMimeType.startsWith('audio');
      return Promise.resolve(isTranscodedMedia ? 'image/webp' : cachedImageMimeType);
    }

    return getMediaContentType(originalImageUrl || '');
  })();

  const animationUrlContentTypePromise = shouldUseCachedAnimation
    ? Promise.resolve(cachedAnimationMimeType)
    : getMediaContentType(originalAnimationUrl || '');

  const [imageUrlContentType, animationUrlContentType] = await Promise.all([
    imageUrlContentTypePromise,
    animationUrlContentTypePromise,
  ]);

  const imageSourceInfo = {
    source: imageUrl,
    mediaType: imageUrlContentType,
  };
  const animationSourceInfo = {
    source: animationUrl,
    mediaType: animationUrlContentType,
  };
  const assets = [animationSourceInfo, imageSourceInfo];

  // test either one and see if it's video, then audio, then image
  const videoSource = assets.find(({ mediaType }) => mediaType?.startsWith('video'));
  const audioSource = assets.find(({ mediaType }) => mediaType?.startsWith('audio'));
  const imageSource = assets.find(({ mediaType }) => mediaType?.startsWith('image'));

  const sourceInfo = videoSource ?? audioSource ?? imageSource ?? imageSourceInfo;

  return {
    mediaType: sourceInfo.mediaType,
    source: sourceInfo.source,
    poster: imageUrl,
    fromCache: Boolean(shouldUseCachedImage || shouldUseCachedAnimation),
  };
}
