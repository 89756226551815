import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from 'cb-wallet-data/hooks/useIsMounted';
import { CachedMediaType } from 'cb-wallet-data/stores/Collection/types';
import { getMediaInfo, MediaInfo } from 'cb-wallet-data/utils/getMediaInfo';
import { RetrievalMethod } from '@cbhq/nft-shared/utils';

import { triggerCachedMediaUnsupportedAnalytics } from './useCollectibleMediaAnalytics';

let MEDIA_INFO_CACHE: Record<string, MediaInfo> = {};

const THIRDWEB_CLIENT_ID = 'b891d14d436694bb9a7feeba91730b95';

// Just for testing
export function resetCache() {
  MEDIA_INFO_CACHE = {};
}

export function addCacheItem(
  contractAddress: string,
  tokenId: string,
  mediaInfo: MediaInfo,
  preview: boolean,
) {
  if (!contractAddress || !tokenId) {
    return;
  }

  MEDIA_INFO_CACHE[getKey({ contractAddress, tokenId, preview })] = mediaInfo;
}

type LocalCacheKeyProps = {
  contractAddress: string;
  tokenId: string;
  preview?: boolean;
};

function getKey({ contractAddress, tokenId, preview = false }: LocalCacheKeyProps): string {
  return `${tokenId}-${contractAddress}-${preview}`;
}

const SUPPORTED_MEDIA_TYPES_REGEX = /(image|video|audio)\//;

const DEFAULT_MEDIA_INFO = {
  source: undefined,
  mediaType: undefined,
  poster: undefined,
  fromCache: true,
};

export function isMediaInfoCached({
  contractAddress,
  tokenId,
  preview = false,
}: LocalCacheKeyProps) {
  if (!contractAddress || !tokenId) return false;

  return !!MEDIA_INFO_CACHE[getKey({ contractAddress, tokenId, preview })];
}

function getDefaultState({ contractAddress, tokenId, preview }: LocalCacheKeyProps) {
  if (!contractAddress || !tokenId) return DEFAULT_MEDIA_INFO;

  const mediaEntry = MEDIA_INFO_CACHE[getKey({ contractAddress, tokenId, preview })];
  return mediaEntry ?? DEFAULT_MEDIA_INFO;
}

type SetMediaInfoFn = (
  newInfo: MediaInfo,
  options?: {
    skipCacheUpdate?: boolean | undefined;
  },
) => void;

type Options = {
  startTime: number;
  chainId: string;
  imageUrl?: string;
  cachedImageUrl?: CachedMediaType;
  animationUrl?: string;
  cachedAnimationUrl?: CachedMediaType;
  tokenId: string;
  contractAddress: string;
  preview?: boolean;
  qualityPercentage?: number;
};

/**
 * Cache media info since components that use this hook can unmount due
 * to list virtualization. This could also be done in react query but it
 * would require a larger refactor
 */
export function useMediaInfo({
  imageUrl,
  cachedImageUrl,
  animationUrl,
  cachedAnimationUrl,
  tokenId,
  contractAddress,
  preview = false,
  startTime,
  chainId,
  qualityPercentage,
}: Options): [MediaInfo, SetMediaInfoFn] {
  const isMountedRef = useIsMounted();
  const [mediaInfo, setMediaInfoInternal] = useState<MediaInfo>(
    getDefaultState({ contractAddress, tokenId, preview }),
  );

  const setMediaInfo: SetMediaInfoFn = useCallback(
    (newInfo, { skipCacheUpdate } = {}) => {
      if (!skipCacheUpdate) {
        addCacheItem(contractAddress, tokenId, newInfo, preview);
      }
      setMediaInfoInternal(newInfo);
    },
    [contractAddress, tokenId, preview],
  );

  const fetchAndSetMediaInfo = useCallback(
    async function fetchAndSetMediaInfo(
      imageURL?: string,
      cachedImageURL?: CachedMediaType,
      animationURL?: string,
      cachedAnimationURL?: CachedMediaType,
      isPreview?: boolean,
    ) {
      if (!isMountedRef.current) {
        return;
      }

      const isImageIPFSHash = cachedImageURL?.cachedPath
        ?.toLocaleLowerCase()
        .includes(THIRDWEB_CLIENT_ID);

      const isAnimationIPFSHash = cachedAnimationURL?.cachedPath
        ?.toLocaleLowerCase()
        .includes(THIRDWEB_CLIENT_ID);

      const retrievalMethod =
        isImageIPFSHash || isAnimationIPFSHash ? RetrievalMethod.FETCH : RetrievalMethod.UPLOAD;

      const data = await getMediaInfo({
        imageUrl: imageURL,
        animationUrl: animationURL,
        cachedImagePath: cachedImageURL?.cachedPath,
        cachedImageMimeType: cachedImageURL?.mimeType,
        cachedAnimationPath: cachedAnimationURL?.cachedPath,
        cachedAnimationMimeType: cachedAnimationURL?.mimeType,
        cachedMediaEnabled: true,
        preview: isPreview,
        retrievalMethod,
        qualityPercentage,
      });

      const { fromCache, mediaType } = data;

      // if the cached mimeType is not supported, we try loading from the source instead
      // this is because our media cache may not return the correct mimeType for SVGs
      if (fromCache && mediaType && !SUPPORTED_MEDIA_TYPES_REGEX.test(mediaType)) {
        triggerCachedMediaUnsupportedAnalytics({
          chainId,
          startTime,
          mediaType,
          contractAddress,
        });

        const fallbackData = await getMediaInfo({
          imageUrl: imageURL,
          animationUrl: animationURL,
          preview: isPreview,
          cachedMediaEnabled: false,
          qualityPercentage,
        });

        setMediaInfo(fallbackData);
      } else {
        setMediaInfo(data);
      }
    },
    [isMountedRef, qualityPercentage, chainId, startTime, contractAddress, setMediaInfo],
  );

  useEffect(
    function fetchAndSetMediaInfoOnTokenChange() {
      if (isMediaInfoCached({ contractAddress, tokenId, preview })) {
        return;
      }

      fetchAndSetMediaInfo(imageUrl, cachedImageUrl, animationUrl, cachedAnimationUrl, preview);
    },
    [
      imageUrl,
      cachedImageUrl,
      animationUrl,
      cachedAnimationUrl,
      tokenId,
      contractAddress,
      preview,
      fetchAndSetMediaInfo,
    ],
  );

  return [mediaInfo, setMediaInfo];
}
