import { QueryMeta } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { ExperimentsAPIResponse, ExperimentsResponse, ReleaseEnvironment } from '../types';
import { fetchWalletUserExperiments } from '../utils/fetchExperiments';

export const EXPERIMENTS_QUERY_KEY = 'get_experiments';
const DEFAULT_EXPERIMENTS: ExperimentsAPIResponse = { groups: [] };

type Props = {
  releaseEnvironment: ReleaseEnvironment;
  version: string;
  userId?: string;
  shouldSuspend: boolean;
  onReceiveFirstAuthedExperimentResult?: () => void;
  meta?: QueryMeta;
  shortLivedQueryCache?: boolean;
  /**
   * @param timeout Milliseconds to wait before ignoring the experiment fetch call and just reject to default value.
   */
  timeout?: number;
};

/**
 * useWalletUserExperiments - Fetches and caches a logged in user's experiments from the Coinbase API.
 *
 * @returns The ExperimentsResponse object.
 * @param releaseEnvironment Current environment, this is used to determine if the current user is an employee.
 * @param version Current app version, used to support version gating on experiments
 * @param userId userId
 * @param shouldSuspend false if caller is already handling loading state, true if a loading state provided by a suspense
 * fallback is needed
 * @param onReceiveFirstAuthedExperimentResult
 */
export function useWalletUserExperiments({
  releaseEnvironment,
  version,
  userId,
  shouldSuspend,
  meta,
  onReceiveFirstAuthedExperimentResult,
  shortLivedQueryCache,
  timeout,
}: Props) {
  const { data } = useQuery<ExperimentsResponse>(
    [EXPERIMENTS_QUERY_KEY, userId],
    async () =>
      fetchWalletUserExperiments({
        userId,
        releaseEnvironment,
        version,
        markHasReceivedFirstAuthedExperimentsResult: onReceiveFirstAuthedExperimentResult,
        timeout,
      }),
    {
      enabled: !!userId, // Do not make the call if the user id is not returned
      suspense: shouldSuspend, // Throw a promise for the first fetch, also throw to ErrorBoundary on fail
      useErrorBoundary: false, // throw to nearest ErrorBoundary on fail
      staleTime: 1000 * 60 * 5, // Refresh the user's experiments when requested again after 5 minute
      cacheTime: shortLivedQueryCache ? 1 : 1000 * 60 * 30, // Cache the experiments for 30 min after the user has closed the app
      notifyOnChangeProps: ['data'],
      refetchInterval: 1000 * 60 * 5, // Automatically refetch every 5 minutes
      meta,
    },
  );

  return data ?? DEFAULT_EXPERIMENTS;
}
